jQuery(function () {
    //var json = {
    //    "country": "",
    //    "bu": ""
    //};
    //var jsonData = JSON.stringify(json);
    //$.ajax({
    //    type: "POST",
    //    url: window.location.href + "GetData",
    //    data: jsonData,
    //    contentType: "application/json; charset=utf-8",
    //    dataType: "json",
    //    success: function (response) {
    //        console.log(response.value);
    //        $(".contact-list-block").html(response.value);
    //    },
    //    error: function (response) {
    //        console.log("error: " + response.value);
    //    }
    //});

    $("#selCountries").on('change', function () {
        var bu = $('#selBUs').find(":selected").val();
        var countryid = $(this).val();
        var json = {
            "country": countryid,
            "bu": bu
        };
        var jsonData = JSON.stringify(json);
        $.ajax({
            type: "POST",
            url: window.location.href + "GetData",
            data: jsonData,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                console.log(response.value);
                $(".contact-list-block").html(response.value);
            },
            error: function (response) {
                console.log("error: " + response.value);
            }
        });
    });


    $("#selBUs").on('change', function () {
        var countryid = $('#selCountries').find(":selected").val();
        var bu = $(this).val();
        var json = {
            "country": countryid,
            "bu": bu
        };
        var jsonData = JSON.stringify(json);
        $.ajax({
            type: "POST",
            url: window.location.href + "GetData",
            data: jsonData,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                console.log(response.value);
                $(".contact-list-block").html(response.value);
            },
            error: function (response) {
                console.log("error: " + response.value);
            }
        });
    });

});





window.openShareDialog = function (url, w, h) {
    window.open(url + encodeURIComponent(location.href), 'ShareDialog', 'width=' + w + ',height=' + h);
    return false;
}

window.CopyLink = (e) => {
    let dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = window.location.href;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    $('#copy-alert').fadeIn();

    setTimeout(hideCopyAlert, 3000);
}

$(function () {
    $('#copy-alert a').on('click', (e) => {
        hideCopyAlert()
    })

    window.fixLiftBlockMargins();

    $(window).resize(window.fixLiftBlockMargins);

    // This fixes liftblocks which content comes from sst's scripts
    setTimeout(function () {
        window.fixLiftBlockMargins();
    }, 500);

    var hideCopyAlert = () => {
        $('#copy-alert').fadeOut();
    }
})

window.fixLiftBlockMargins = () => {
    $('.liftblock-bg').each((i, e) => {
        fixLiftBlock(e);
    });

    function fixLiftBlock(block) {
        var liftblockHeight = $(block).innerHeight();
        var liftblockContentHeight = $(block).children('.liftblock-content').innerHeight();

        if ($(window).width() > 1199) {
            $(block).css('margin-bottom', liftblockContentHeight - liftblockHeight + 60);
        }
        else if ($(window).width() > 991) {
            $(block).css('margin-bottom', liftblockContentHeight - liftblockHeight + 90);
        }
        else {
            $(block).css('margin-bottom', liftblockContentHeight - 100);
        }
    }
}

window.initAutocomplete = function (element) {
    element.autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/find_v2/_autocomplete?prefix=" + encodeURIComponent(request.term) + "&size=5",
                success: function (data) {
                    response($.map(data.hits, function (item) {
                        return {
                            label: item.query,
                            value: item.query
                        };
                    }));
                }
            });
        },
        classes: {
            'ui-autocomplete': 'autocomplete-' + element.attr('id')
        },
        minLength: 2,
        select: function (e, ui) {
            element.val(ui.item.value).parent().submit();
        }
    });
}



jQuery(function () {
    //Header color change 
    if ($(window).scrollTop() > 50) {
        $(".main-header").addClass("active");
    }

    $(window).on("scroll", function () {
        if ($(window).scrollTop() > 50) {
            $(".main-header").addClass("active");
        } else {
            //remove the background property so it comes transparent again (defined in your css)
            $(".main-header").removeClass("active");
        }
    });

    //Add table responsive frame 
    $('table').wrap("<div class='table-responsive'></div>");

    //Search toggle
    $('.toggle-search').on('click', function () {
        $(this).toggleClass('open');
        $(this).hasClass('open') ? $(this).attr('aria-expanded', 'true') : $(this).attr('aria-expanded', 'false');
        $('.main-header').addClass('active');
        $('.search-field').fadeToggle('fast');
    });

    $(document).on('keydown', function (e) {
        if (e.key == "Escape") {
            $(".toggle-search").removeClass('open').attr('aria-expanded', 'false');
            $('.search-field').fadeOut('fast');
        }
    });

    //Accordion

    $('.accordion-btn').on('click', function () {
        $(this).toggleClass('open');
        //$(this).parent('.accordion-item').siblings().children('.accordion-btn').removeClass('open').attr('aria-expanded', 'false');
        //$(this).parent('.accordion-item').siblings().children('.accordion-content').slideUp();
        $(this).hasClass('open') ? $(this).attr('aria-expanded', 'true') : $(this).attr('aria-expanded', 'false');
        $(this).siblings('.accordion-content').slideToggle();
    });

    //Mobile side menu 

    $('#side-navigation .mobile-btn').on('click', function() {
        $(this).toggleClass('open');
        $(this).siblings('.menu').slideToggle();
        $(this).hasClass('open') ? $(this).attr('aria-expanded', 'true') : $(this).attr('aria-expanded', 'false');
    });

    $(window).on('resize', function () {
        $('.nav-item .collapse-toggle').removeClass('open').attr('aria-expanded', false);
        $('.nav-item .language-toggle').removeClass('open').attr('aria-expanded', false);
        $('.nav-item .collapse').hide();

        if (window.innerWidth > 991) {
            $('#side-navigation > ul').show();
        } else {
            $('#side-navigation > ul').hide();
            $('#side-navigation .mobile-btn').removeClass('open').attr('aria-expanded', 'false');
        }
    });

    //Show more-button
    $('.show-more').on('click', function () {
        if ($(this).parent('.col-12').parent('.row').prev('.row').children("div[class^='col']:hidden").length > 0) {
            $(this).parent('.col-12').parent('.row').prev('.row').children("div[class^='col']:hidden").slideDown('fast');  
        }
        $(this).attr('aria-expanded', 'true');
        $(this).fadeOut('fast');
    });
});